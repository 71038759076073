export const notifyMessage = {
  errorMessage: {
    NETSUITE_FUSION_ALLOW_JUST_USD:
      'The Internal ID entered cannot be linked. The Marketplace Account is a Fusion company and by default uses only USD currency. Use an account with USD currency and try again.',
    NETSUITE_NOT_ALLOW_VENDOR_AND_FUSION:
      'The Internal ID entered cannot be linked. The Marketplace Account is a Fusion company and we can link only to a Vendor account. Use another ID and try again.',
    FILE_DOWNLOAD_FAILED:
      'Error: Unable to download the file. Please refresh and try again.',
    FILE_UPLOAD_MISSING: 'Error: File must be uploaded to continue',
    FILE_UPLOAD_ASK_EXTERNAL_BUYER:
      'Error: Please upload file in .xls/.xlsx/.csv format to continue',
    FILE_UPLOAD_ASK_IPSOS:
      'Error: Please upload file in .csv format to continue',
    FILE_UPLOAD_ERROR_GENERIC:
      'Error: Please upload file in <FILE_EXTENSION> format to continue',
    INVALID_CREDENTIALS: 'Please check your email and password and try again',
    UNKNOWN_ERROR_5XX:
      'There was an issue connecting your browser session to our servers. Please check your Internet connection and try again.',
    NO_DATA_ERROR:
      'Error:  Unable to retrieve data. Please refresh and try again',
    INVALID_PSID_FILE:
      'PSID file is empty / invalid. Please update to continue',
    UPLOAD_PSID_FILE: 'Please upload respondent list to continue',
    API_ERROR: 'Something went wrong, try again',
    EMPTY_PSID_ERROR: 'Invald or empty PSID list in uploaded file',
    SERVER_ERROR:
      'Error: An unexpected server error has occurred. Please refresh and try again',
    COUNTRY_MISSING: 'Error: Please select a country.',
    QUALIFICATION_MISSING: 'Error: Please select a qualification.',
    INVALID_EMAIL: 'Please enter a valid email address',
    EPC_REPORT: {
      NO_DATA_ERROR:
        'Error:  No record has been found for selected buyer. Please add to continue',
    },
    INVALID_DATE_RANGE: 'Please select the date range',
    NO_BUYER_SELECTED: 'Please Select Buyers from the List!',
    NO_SUPPLIER_SELECTED: 'Please Select Suppliers from the List.',
    NO_STATUS_SELECTED: 'Please Select a Status.',
    NO_RESPONDENT_STATUS_SELECTED: 'Please Select a Respondent Status.',
    PSID_REPORT: {
      NO_DATA_FOUND:
        'Error: No record has been found. Please update to continue',
      INVALID_ENTRY: 'Error: Invalid entry. Please update to continue',
    },
    OPERATION_UNAVAILABLE: 'Error: Operation unavailable',
    COMPANIES_NOT_FOUND:
      'Error: Unable to retrieve Companies. Please refresh and try again',
    INVOICE_REGENERATE_FAILED:
      'Error: Unable to regenerate invoice. Please refresh and try again',
    ALL_FIELDS_REMAINING:
      'Error: All fields must be populated. Please update to continue',
    URL_UPDATE: 'Error:  Please update URL to continue',
    NO_ZIP_CODE_FOUND: 'No Zipcode Found',
    INVALID_LOCATION_CODE_FILE:
      'The file has been imported but it contains invalid ZIP/Post code(s).\nIf you use the “DOWNLOAD CURRENT FILE” option, we will indicate which Postcodes are ‘valid’ and which are ‘invalid’.',
    INVALID_FSA_CODE_FILE:
      'The file has been imported but it contains invalid FSA code(s). \nIf you use the "DOWNLOAD CURRENT FILE" option, we will indicate which FSA codes are `valid` and which are `invalid`.',
    QUOTA_MODAL: {
      EMPTY_RANGE_ROW: 'Row cannot be left empty.',
      INVALID_RANGE_YEAR_MONTH:
        'Age cannot be greater than 18 in years, or 48 in months.',
      MISSING_GENDER: 'Gender must be selected. Please update to continue',
    },
    SURVEY_LAUNCH: {
      EMPTY_LIVE_URL: 'Please enter valid Live URL',
      EMPTY_SCHEDULE_LAUNCH:
        'Error: Please update the project launch date / time to continue.',
      LIVE_URL_ERR_FROM_API:
        'Your survey requires a valid Live URL before launching. Please add a Live URL before retrying.',
      UNIQUE_BUYER_LINKS_NOT_UPLOAD:
        'Please upload Unique Buyer entry links to continue.',
      CPI_COMPENSATION_CONFIRMED:
        'CPI and Compensation must be confirmed before proceeding',
      PRICE_OVERRIDE_NOT_FOUND:
        'Surveys on this account require Price Override.  Please set via the Define / Edit Audience page or Supplier Allocations view',
    },
    MULTICOUNTRY_DRAFT: {
      REJECTED_SURVEYS:
        'The system encountered a problem to create the survey for',
      FOOTER_FINALIZE_AND_LAUNCH:
        'Error: Please click to "Save all Survey as Draft” before Finalize and Launch',
      FOOTER_SAVE:
        'Error: Please select the country and save as draft the projects',
    },
    MULTICOUNTRY: {
      LOCALE_ALREADY_EXISTS:
        'Error: The survey already exists in the Multi-Country group for locale <LOCALE>. Try again with another locale.',
      MANUAL_PROCESSING_APPLYING_TEMPLATE:
        'Please, click `Save and Process` before applying the Template',
    },
    Quota: {
      STATUS_CHANGED_ERROR_MESSAGE: 'one or more Quotas cannot be changed!',
    },
    SUPPLIER_GROUP: {
      EDIT_SUPPLIER_GROUP:
        'In order to change existing Supplier Group, please use the edit icon next to the group.',
      SUPPLIER_GROUP_MORE_THAN_ONE:
        'Supplier group should have more than one Supplier',
      CONFIRM_SUPPLIER_CPI_FOR_GROUP: 'CPI value must be confirmed',
      CPI_REQUIRE: 'CPI is required',
      GROUP_NAME_REQUIRE: 'Group Name is required',
    },
    SUPPLIER_RATE_CARD: {
      INVALID_CSV_FILE:
        "This CSV file won't work. Please use the template, provided below and try again.",
      FORMULA_SAVED_ERROR:
        'Please make sure to provide all required information before proceeding.',
    },
    DRAFT_SURVEY_DELETE: 'Error: The draft survey could not be deleted.',
    NIGHTCRAWLER: {
      BUYER_INFO_UNAVAILABLE:
        'Error:  Unable to retrieve buyer information. Please refresh and try again',
      UNABLE_TO_SYNC:
        'Error:  Unable to sync survey. Please refresh and try again',
      MISSING_REDIRECT_SETTINGS:
        'Error:  Buyer redirect settings missing. Please update to continue',
      INCORRECT_SURVEY_ENTRY_LINK:
        'Error:  Incorrect survey entry link. Please update to continue',
      INVALID_BUYER_ENTRY_LINK:
        'Links must contain a placeholder for the transaction id to be valid. For example, "https://yoursurvey.com/surveyId?transaction_id=[%%buyer_transaction_id%%]&session_id=[%%supplier_session_id%%]"',
    },
    SUPPLIER_PAYLOAD: {
      FLEXIBILITY_GREATER_THAN_HUNDRED:
        'Error:  Flexibility cannot be greater than 100%. Please update to continue',
      CPI_VALUE_CONFIRMED: 'CPI value must be confirmed',
      ALLOCATION_LESS_THAN_COMPLETES:
        'Error:  Allocations must be greater than or equal to the number of completes. Please update to continue',
    },
    FEASIBILITY_PROCESSING: 'Error: Feasibility could not be processed',
    SOFT_LAUNCH_ERROR_MESSAGE:
      'Soft Launch and Field Evenly Over Time can not be applied at the same time on a project.',
    RECONTACT_RESPONDENT_LIST:
      'Error:  Respondent list must be uploaded before testing. Please update to continue',
    QUESTION_LIBRARY: {
      QUESTION_TEXT_COPY: 'Problem in copying Question text! Please try again.',
    },
    ID_UPLOAD_ERROR: {
      CRM_UPDATE_ERROR: 'Error while updating CRM ID',
      ASANA_UPDATE_ERROR: 'Error while updating ASANA ID',
    },
    DOWNLOAD_REPORT_ERROR: 'Something went wrong! Please try again.',
    NETSUITE: {
      INVALID_ID:
        'In order to link the Marketplace Account in NetSuite you must enter a valid Internal ID',
      NOT_FOUND: 'The Internal ID entered is not found.',
      INVALID_CURRENCY:
        'The Internal ID entered cannot be linked. The Marketplace Account doesn’t have any currency selected. Select the currency above and try again.',
      INVALID_CURRENCY_MATCH:
        'The Internal ID entered cannot be linked. The NetSuite company doesn’t support the currency set for this account. Make sure both companies support the same currency.',
    },
    BUYER_SETTINGS: {
      PO_RULES: {
        BILLING_NUMBER_MIN_CHAR:
          'The billing number cannot be shorter than :CHAR chars.',
        BILLING_NUMBER_MAX_CHAR:
          'The billing number cannot be longer than :CHAR chars.',
        BILLING_NUMBER_RANGE_INVALID:
          'The billing number range is invalid, please change and try again. The maximum must be greater than the minimum character value,',
        BILLING_NUMBER_MIN_MAX_INVALID_RANGE:
          'Value must be greater than the minimum and less than the maximum defined number of characters. Please update to continue.',
        BILLING_RULES_CANNOT_BE_EMPTY:
          'Rule definition in last entered row is not completed. Ensure the Range, Rule and Type columns are filled out and try to save again.',
        BILLING_RULE_REQUIRED_CHARACTERS_LENGTH:
          '<b>INVALID RULESET - Missing characters.</b> <br> The current ruleset does not define the total specified range of characters.<br> Missing characters: :MIN_CHAR - :MAX_CHAR. <br>Please add a rule to define the remaining characters.',
        FIXED_RULE_UNDERFLOW_OVERFLOW:
          "Please review that the Rule Input matches the Rule's Character Range. (ie range 1-5 needs 5 characters input",
        LENGTH_MAX_CHAR_INVALID:
          'Remove the rule definition if you want to decrease the Max Character value ',
        INVALID_RULE_TITLE: 'INVALID RULE - Character Range.',
        INCOMPLETE_INPUT_TITLE: 'INVALID RULE - Incomplete Input',
        NON_SEQUENTIAL_TITLE: 'INVALID RANGE- Non-Sequential.',
        FIXED_CHARACTER_INVALID_RANGE_TITLE:
          'INVALID RULE - Fixed Character Input Length',
        INVALID_MINIMUM_RANGE_TITLE: 'INVALID RANGE - :CHAR Characters.',
        MISSING_RULE_SET_TITLE: 'INVALID RULESET - Missing characters.',
      },
    },
    FIXED_RULE_INVALID_CHARACTER:
      'Use only characters and symbols from this range: “a-z”, “A-Z”, "0-9” and “{} [] () /  ~ - , ; : . < > _ * + & # @“ for the fixed part of the billing number',
    FIXED_CHARACTER_LIMIT_EXCEED:
      'The number of characters in the billing rules cannot exceed the maximum defined number of characters.',
    CONDITION_MAPPING_ERROR: {
      ATLEAST_ONE_MAPPING: 'Please Select atleast one Mapping.',
      PARTIAL_MAPPING: 'Please complete or remove partially mapped options.',
      MISSING_MAPPING: 'Please map all the options',
      INVALID_MAPPING: 'Mapping is Invalid.',
      NO_LOCALE_FOUND: 'No Quota/Mappings found for above locales.',
      NO_MAPPINGS_FOUND:
        'No Quota/Mappings found for selected Country & Language.',
      ATLEAST_ONE_COUNTRY: 'Please select, at least, one available country.',
      SELECT_QUALIFICATION: 'Please Select a Qualification.',
      ALL_DEFAULT_MAPPING: 'Please do Mapping for all Qualifications.',
      CONTACT_PURESPECTRUM:
        'Please change the name of the question as it already exists.',
      GENERIC_ERROR: 'Something Went wrong.',
      DUPLICATE_MAPPING: 'Please remove duplicate Mappings.',
    },
    STAFF_ACCESS_REQUIRED: 'Staff or Supervisor access required.',
    OPERATOR_SETTINGS: {
      DATA_QUALITY: {
        INVALID_PURESCORE_THRESHOLD:
          "Invalid threshold settings. Please check 'Country' and 'Language' fields.",
      },
      GLOBAL_SETTINGS: {
        INVALID_SIMILAR_SURVEY_SEARCH_LIMIT:
          'Similar Survey search limit time must have a value greater than 0',
        INVALID_ACTIVITY_LOG_EXPIRATION_TIME:
          'Activity log expiration time must have a value greater than 0',
      },
    },
    BULK_EDIT: {
      REPORT_DOWNLOAD: {
        NO_SELECTION: 'No surveys selected for downloading combined report',
      },
      BILLING_CODE_ALREADY_USED:
        'This BILLING CODE has already been used for another invoiced surveys.',
      PO_NUMBER_SPACES_NOT_ALLOWED:
        'Spaces are not permitted. Special Characters that are permitted are “{} [] () / ~ - , ; : . < >“.',
      PO_NUMBER_SPACE_NOT_ALLOWER_TITLE: 'INVALID RULE - Invalid Character',
      EDIT_CPI: {
        EMPTY_VALUE: 'New CPI value cannot be empty or 0.',
      },
    },
    MARGIN_MIN_MAX_VALUE_ERROR:
      'Margin should be between -99% and 99% on survey creation',
    MARGIN_VALUE_UPDATE_ERROR:
      'New Margin value cannot be set in a Not Margin target survey.',
    EMPTY_MARGIN_VALUE: 'New Margin value cannot be empty or 0.',
    FILE_UPLOAD_ASK_FEASIBILITY:
      'Error: Please upload file in .csv, .xls format to continue',
  },
  warningMessage: {
    NO_FILE: 'Please select a file before proceeding.',
    INCORRECT_FILE_FORMAT:
      'This file is in the incorrect format. Please see the example file.',
    COMPLETE_REQUIRED: `Please complete all required fields.`,
    SURVEY_REQUIRED_COMPLETE_NOT_FOUND:
      'Required completes not found for selected survey.',
    CENSUS: {
      COMPLETE_REQUIRED: `Please complete all required fields. Census Percentage is missing.`,
      INCORRECT: `You have used an incorrect Census Percentage, please correct this before saving.`,
    },
    DAILY_STATISTICS: {
      COUNTRY_REQUIRED: `Please select the country.`,
      DATERANGE_REQUIRED: `Please select the date range`,
    },
    INVALID_RANGE: {
      MIN_GREATER_THAN_MAX: `You have entered an invalid range. #FROM VALUE# should be less then #TO VALUE#. Please correct this before saving.`,
      OVERLAPPING: `You have entered an invalid range. The input are overlapping. Please correct this before saving.`,
      OVERLAPPING_FROM: `You have entered an invalid range. #FROM VALUE# is overlapping with existing ranges. Please correct this before saving.`,
      OVERLAPPING_TO: `You have entered an invalid range. #TO VALUE# is overlapping with existing ranges. Please correct this before saving.`,
    },
    EPC_REPORT: {
      DATERANGE_COMPANY_REQUIRED:
        'Error:  Please select company and date range to continue',
    },
    INCOMPLETE_FIELDS: 'Please enter all fields.',
    NO_COMPANIES_FOUND:
      'Warning: No companies found. Please update to continue',
    COMMON_NO_RECORD_FOUND: 'Warning:  No record found',
    LAUNCH_SURVEY: {
      SURVEY_REMOVE_FROM_PRISTINEGROUPING: `Removing surveys from this list is not recommended, as it may allow respondents who previously completed surveys to take the surveys again`,
    },
    QUOTA_MODAL: {
      MISSING_NO_OF_COMPLETE:
        'Please specify total number of completes before setting quotas.',
      INVALID_QUOTA_COMPLETE:
        "The total number of completes allocated in the quotas doesn't equal the total number of completes remaining.",
      INVALID_RANGE:
        'Value must be greater than the minimum and less than maximum. Please update to continue.',
    },
    MULTICOUNTRY_DRAFT: {
      SELECT_MULTICOUNTRY: 'Please select multicountry row',
    },
    QUOTA: {
      SELECT_QUOTA_LOCK_UNLOCK: 'Please Select the Quota to Lock/Unlock',
      MAXIMUM_QUOTAS_TO_NEST:
        'You may only nest a maximum of :NESTED_QUOTA_LIMIT qualifications at a time',
    },
    COUNTRY_CHANGE: {
      LOST_QUALIFICATIONS:
        'The new country doesn’t support <QUALIFICATIONS_NAME> qualifications applied before, therefore are removed.',
    },
    GROUPING_SINGLE_QUOTA: 'alert 3 : Grouping for single Quota not allowed',
    GROUPING_CENSUS_REPO: 'Quota Grouping is not allowed over Census Repo',
    QUESTION_LIBRARY: {
      ENTER_ALL_FIELDS: 'Please fill all required fields',
      REQUIRED_ANSWER_OPTIONS: 'Please enter Answers Options Id and Text',
      DUPLICATE_LOCALE: 'Screener Question already exist for this locale',
      REQUIRED_RESPID: '%%respId%% required in Question Screener Text',
      CORE_QUESTION_NOT_ALLOWED: 'Creating Core Class Question is not allowed',
      ADD_LOCALIZATION: 'Please add localization',
      REQUIRED_STEM_RESPID: '%%respId%% required in stem1',
      REQUIRED_ANSWER_TRANSLATION: 'Please add translation for Answers Options',
      ANSWER_ID_ALREADY_EXISTS: 'Answers Options Id already exist',
      OPTION_TEXT_ALREADY_EXISTS: 'Answer Option text already exists',
      ANSWER_AND_TRANSLATION_MISMATCH:
        'Answers options and Answers translation missmatch',
      REQUIRED_COMPONENT_TYPE: 'Please add component type',
    },
    COMPLETES_AND_INCIDENCE_REQUIRED:
      'Warning:  Please add Completes and Incidence to continue',
    UNSUPPORTED_PARENT_QUALIFICATION:
      'You are attempting to add a qualification having a dependency that we currently do not support in our system. Please check your selection and try again.',
    TRAFFIC_CHANNEL_MODAL: {
      QBP_QUOTA_OPTION_NOT_SELECTED:
        'This survey has Quota Based Pricing enabled and requires that you assign a Traffic Channel to a quota.',
    },
    NO_LOCATION: 'No location qualifications/quotas to clear',
    SUPPLIER_GROUP: {
      DISABLE_CPI_OVERRIDE: 'Disable the CPI Override, to customise the CPI',
    },
    BUYER_SETTINGS: {
      KEY_OR_PRIVATE_KEY_MISSING:
        'Shared private key and key name cannot be missing. Please update to continue',
      SUPPLIER_GROUP_NAME_CHAR_LENGTH:
        'Supplier group name not more than 30 characters long.',
    },
    FILE_UPLOAD_MISSING: 'File must be uploaded to continue',
    INVALID_FILE_EXTENSION:
      'Please upload file in <FILE_EXTENSION> format to continue',
    TEMPLATES: {
      TEMPLATE_MODIFICATION_NOT_ALLOWED:
        'This template cannot be updated because you are not the owner. Please Save as new template.',
    },
    INVALID_NUM_OF_COMPLETES:
      'Completes can not be set lower than Total Fielded.',
    OPERATOR_SETTINGS: {
      GLOBAL_SETTINGS: {
        DYNAMIC_PROFILER_MAXIMUM_QUESTIONS:
          'Max Number Of Questions is not valid',
        DATA_QUALITY_QUESTION_INVALID_VALUE:
          'Please enter a value more than 0 for Data Quality Question',
        TLQ_QUESTION_INVALID_VALUE:
          'Please enter a value more than 0 for Terminate Low Quality Questions',
      },
    },
    RACE_QUALIFICATION_REMOVED:
      'This Draft has been updated to remove targeting and/or quotas for Race (214).  If you still need this targeting you can add it back in using the Advanced Targeting Question “Race (Hispanic Included).”',
    MULTICOUNTRY: {
      MANUAL_PROCESSING_BROWSE_ANOTHER_COUNTRY:
        'Please, click `Save and Process` before browsing to another Country/Locale.',
    },
    INVALID_SUPPLIER_CURRENCY: 'Supplier account must use USD Dollar currency',
    CHECK_EXTERNAL_ID:
      'Please check if survey external ID will be used on this buyer account, and disable it.',
    DECIPHER_KEYRING_REQUIRED: 'Please select a keyring.',
    MARGIN_BELOW_THAN_AVERAGE:
      'You need to either confirm the Margin warning notification or set the Margin above 50%.',
  },
  successMessage: {
    FILE_ADDED: 'File added successfully',
    LOGOUT_SUCCESS: 'Logout Successfully',
    PSID_CLEAR_SUCCESS: 'PSID cleared successfully',
    SUCCESSFULL_SAVED: 'Successfully SAVED!',
    SURVEY_ADDED: 'Success! Survey data has been saved',
    SURVEY_UPDATED: 'Success! Survey data has been updated',
    SUPPLIERS_UPDATED: 'Suppliers Updated.',
    SUPPLIER_INFO_UPDATED: 'Success! Supplier information has been saved.',
    HAS_MODIFIED_LOCATION_CODE:
      'successfully uploaded. Some Lines Were Modified Because They Were Only 4 or 3 Digits Long',
    RIGHT_LOCATION_CODE: 'successfully uploaded',
    DUPLICATED_SKIPPED: 'duplicate <QUALIFICATION_NAME> skipped.',
    LOCATION_CLEARED: 'cleared successfully',
    BUYER_SETTINGS: {
      VARIABLE_MAPPING: {
        SAVE_SETTING: 'Success! Buyer information has been added',
      },
    },
    ADV_QUESTION_ADDED: 'Success! Successfully Added',
    ADV_QUESTION_REMOVED: 'Success! Target has been removed',
    SURVEY_DETAIL_QUOTA: {
      QUOTA_LOCKED: 'Quota Locked',
      QUOTA_UNLOCKED: 'Quota Unlocked',
    },
    MULTICOUNTRY_DRAFT: {
      SAVED_SUCCESS: 'All surveys selected are saved as draft',
      LAUNCH_SUCCESS: 'All surveys are successfully launched',
      NEW_SURVEY_ADDED: 'Success! New Survey Added',
    },
    DRAFT_SURVEY_DELETED: 'Success! Draft survey has been deleted.',
    QUOTA_MODAL: {
      QUOTA_ADDED: '<QUALIFICATION_NAME> Quota Added',
    },
    SUPPLIER_RATE_CARD: {
      FORMULA_SAVED_SUCCESS: 'Success! Rate card has been saved',
    },
    QUESTION_LIBRARY: {
      QUESTION_ADDED: 'Question Added Successfully',
      QUESTION_UPDATED: 'Question Updated Successfully',
      QUESTION_DELETED: 'Question Deleted Successfully',
      LOCALIZATION_ADDED: 'Localization Added Successfully',
      LOCALIZATION_DELETED: 'Localization Deleted Successfully',
      ANSWER_OPTION_ADDED: 'Answers Options Added',
      ANSWER_OPTION_DELETED: 'Answers Options Deleted',
      QUESTION_TEXT_COPIED: 'Question text copied!!',
    },
    CPI_UPDATED: 'Success! CPI has been updated',
    PROJECT_OPERATOR_REMOVED: 'Success! Project Manager removed successfully',
    PROJECT_OPERATOR_ASSIGNED: 'Success! Project Manager assigned successfully',
    BUYER_PROJECT_MANAGER_REMOVED:
      'Success! Buyer Project Manager removed successfully',
    BUYER_PROJECT_MANAGER_ASSIGNED:
      'Success! Buyer Project Manager assigned successfully',
    CLEAR_RESPONDENT_LIST: 'Success! PSID has been cleared.',
    OPERATOR_SETTINGS: {
      DATA_QUALITY: {
        PURESCORE_THRESHOLD_UPDATED: 'PureScore Threshold Updated',
        PURESCORE_THRESHOLD_CREATED: 'PureScore Threshold Created',
      },
      CRM_ID: {
        FILE_UPLOADED: 'Uploaded CRM ID',
      },
      GLOBAL_SETTINGS: {
        SETTINGS_SAVED: 'Successfully SAVED!',
      },
    },
    TEMPLATES: {
      TEMPLATE_SAVED: 'Template saved successfully',
      TEMPLATE_UPDATED: 'Template updated successfully',
    },
    CPI_FEASIBILITY_ALREADY_UPDATED:
      'The CPI and Feasibility is already updated with the latest changes. Click ”Save as Draft” to continue.',
    SURVEY_DETAIL: {
      SINGLE_SURVEY_INVOICED:
        'Success! Your survey <SURVEY_ID> is generating an invoice',
      BULK_SURVEYS_INVOICED: 'Success! All surveys are generating invoices',
    },
    BULK_EDIT: {
      OPERATION_COMPLETED:
        'Bulk Operation completed successfully: <OPERATION_TYPE>',
      OPERATION_FAILED:
        'Error while running the Bulk Operation. One or more surveys encounter a problem, please check the list below and try again.',
    },
    BILLING_NUMBER: {
      UPDATED: 'Success! Billing PO/Project Number has been updated',
    },
    FEASIBILITY_UPLOADED: 'Feasibility uploaded successfully.',
  },

  infoMessage: {
    FILE_UPLOAD_IN_PROCESS: 'Your file is uploading and being processed.',
    MC_SINGLE_VIEW: {
      CLONE_MC_SVP_NOT_AVAILABLE:
        'CLONE a Multi-country Single Survey view is not available now. We will add this functionality soon.',
    },
    PUREPRICE_UPLOAD_IN_PROCESS:
      'A new version of PurePrice rate card for <COUNTRY> will be processed. A success message will be displayed in #<SLACK_CHANNEL> slack channel. Estimated time: <ESTIMATED_TIME>',
  },

  surveyDetailErrorMessage: {},
  OUT_OF: 'out of',

  TRAFFIC_CHANNEL_SURVEY_CREATED_SUBPART:
    'Traffic Channel Survey Created for Survey',
  CPI_CONFIRMATION_ERROR:
    'You need to either confirm the Price warning notification or set the CPI lower than 10.',
  CPI_CONFIRMATION_ERROR_ON_STATUS_CHANGE:
    'You need to confirm the High CPI before the new status is approved.',
};
